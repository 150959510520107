.title_heading {
  text-align: center;
  padding-top: 25vh;
  font-size: 80px;
  color: white;
}

.home_background {
  background-image: url('../img/balconies2_resized.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.soc_med_icon {
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  flex: 1;
}

.icon:hover {
  transform: scale(1.1);
}

.soc_med_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  justify-self: center;
  margin: 5vh 60vh;
}