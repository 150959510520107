html {
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.bg-CO250 {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55), 
    rgb(0, 0, 0, 0.55)
  ),
  url('../img/systems-of-equations_resized.jpg');
  background-repeat: no-repeat;
  background-size: 200% 100%;
  width: 50vw;
  height: 100vh;
  filter: grayscale(10%);
}

.bg-Seaghans-Fractions {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55), 
    rgb(0, 0, 0, 0.55)
  ),
  url('../img/pipes_resized.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 50vw;
  height: 100vh;
  filter: grayscale(10%);
  padding-left: 0vh;
}

.bg-Seaghans-Matrices {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55), 
    rgb(0, 0, 0, 0.55)
  ),
  url('../img/brownboard_resized.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 50vw;
  height: 100vh;
  filter: grayscale(10%);
}

.project-description {
  padding-left: 0vh;
  color: whitesmoke;
  font-size: 3vh;
  list-style: circle;
  font-style: normal;
}

.headerTab {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px;
  float: right;
  align-self: flex-end;
  padding-top: 2vh;
  order: none;
}

.headerTab:hover {
  transform: scale(1.1);
}

.project-section-header {
  color: whitesmoke;
  font-size: 180px;
  text-align: center;
  padding-left: 0vh;
  padding-top: 5vh;
  padding-bottom: 0vh;
}

.project-goal-section {
  padding-left: 0vh;
  color: white;
  font-size: 3vh;
  list-style: circle;
  font-style: normal;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.project-text {
  padding-left: 2vh;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
/*
.langBar {
  display: flex;
  flex-direction: row;
}*/
/*
.langTab {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px;
  float: left;
  padding-top: 2vh;
  align-self: flex-start;
}*/

.langTab:hover {
  transform: scale(1.1);
}

.github_link {
  color: white;
  width: 60px;
  height: 60px;
  padding: 10vh;
}

.centreImage {
  padding: 2vh;
}

.subtitle_heading {
  text-align: center;
  justify-content: center;
  justify-self: center;
  padding-top: 10vh;
  padding-left: 10vh;
  font-size: 45px;
  color: white;
}

.ProjectBG {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55), 
    rgb(0, 0, 0, 0.55)
  ),
  url('../img/projectjournal_resized.jpg');
  background-repeat: no-repeat;
  background-size: 100% 300%;
  max-height: 300px;
  max-width: cover;
  filter: grayscale(10%);
}

li {
  color: white;
  padding-left: 0vh;
  padding-top: 0vh;
  padding-right: 0vh;
  list-style: none;
  font-style: bold;
  font-size: 4vh;
  filter: none;
}

ul {
  padding-top: 0vh;
  padding-left: 0vh;
  padding-right: 0vh;
}

.sub_elem {
  padding-left: 10vh;
  padding-top: 0vh;
  color: rgb(15, 23, 51);
  list-style: none;
  font-style: normal;
  font-size: 1vh;
}

.bullet {
  color: white;
  font-style: oblique;
  font-size: 3vh;
  white-space: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.programming_lang {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: whitesmoke;
  font-size: 4vh;
}

.project_title {
  color: rgb(255, 188, 213);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 4vh;
}

.docLink {
  color: white;
  text-decoration: none;
  font-style: italic;
  padding: 0vh;
}