.langTab {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px;
  float: left;
  padding-top: 1vh;
  display: flex;
}

.langTab:hover {
  transform: scale(1.1);
}

.topNavBar {
  background: black;
  display: flex;
  flex-direction: row;
  z-index: 3;
  position: absolute;
  justify-self: center;
  justify-items: center;
  justify-content: center;

  
  min-width: 100%;
}

.pageNav {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  text-decoration: none;
}

.pageNav:hover {
  transform: scale(1.1);
}

.navToggle {
  z-index: 4;
  position: absolute;

  border: none !important;
  font-size: 20px;
  background: transparent;
  color: whitesmoke;
  padding: 10px 15px;
}

.linkBar {
  background-color: transparent;
  display: flex;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
