.ExpBG {
  background-color: rgb(34, 34, 34);
}

.company {
  color: rgb(223, 126, 190);
  padding-left: 10vh;
  font-size: 9vh;
}

.company-large {
  color: rgb(223, 126, 190);
  padding-left: 10vh;
  font-size: 10vh;
}

.exp-section-header {
  color: whitesmoke;
  font-size: 200px;
  text-align: center;
  padding-left: 0vh;
  padding-top: 3vh;
  font-style: italic;
}

.job_title {
  font-style: oblique;
  font-size: 6vh;
  padding-left: 10vh;
  color: whitesmoke;
}

ul {
  padding-left: 0vh;
}

.pagebox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0vh;
}

.HHD_background {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(83, 83, 83, 0.5)
  ),
  url('../img/wine_grapes.jpg');
  background-repeat: no-repeat;
  background-size: 120% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
}

.Target_background {
  background-image: url('../img/target_pallettes_resized.jpg');
  background-repeat: no-repeat;
  background-size: 280% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
}

.Portillos_background {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(83, 83, 83, 0.5)
  ),
  url('../img/hotdogs.jpg');
  background-repeat: no-repeat;
  background-size: 280% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
}

.Math_ambassador_background {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(83, 83, 83, 0.5)
  ),
  url('../img/building_worms_eye.jpg');
  background-repeat: no-repeat;
  background-size: 280% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
}

.text_block_left {
  width: 120vw;
}

.header_background {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(223, 126, 190, 0.5)
  ),
  url('../img/man_infrontof_stairs.jpg');
  background-size: 100% 300%;
  background-repeat: no-repeat;
}