.programming {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(34, 34, 34, 0.5)
  ),
  url('../img/programming_resized.jpg');
  background-repeat: no-repeat;
  background-size: 100% 300%;
}

.language {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(34, 34, 34, 0.5)
  ),
  url('../img/greekbook_resized.jpg');
  background-repeat: no-repeat;
  background-size: 100% 300%;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bullet {
  padding-left: 0vh;
  color: white;
  font-size: 3vh;
  list-style: circle;
  font-style: normal;
}

.tab {
  padding-left: 5vh;
}

.skill-section-header {
  font-style: italic;
  text-align: center;
  font: Courier;
  font-size: 100px;
  padding-top: 10vh;
  padding-left: 0vh;
  padding-bottom: 0vh;
  color: whitesmoke;
}

.body {
  padding-top: 5vh;
  padding-left: 10vh;
  padding-bottom: 5vh;
}

.skills-content {
  position: relative;
}

.skills-tags {
  display: flex;
  flex-direction: column;
  
}

.skills-tag {
  padding-left: 0vh;
  color: rgb(255, 188, 213);
  font-size: 3vh;
  font-style: normal;
  position: relative;
  align-self: flex-start;
}

.skills-bullet {
  display: flex;
  flex-direction: row;
  font-size: 3vh;
  padding-left: 0vh;
  justify-content: space-between;
  padding-top:1vh;
}

.skills-descr {
  color: whitesmoke;
  align-self: flex-end;
  align-content: flex-end;
  padding-right: 5vh;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: right;
}