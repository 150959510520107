.bio_subtitle {
  text-align: left;
  padding-top: 5vh;
  font-size: 25px;
  color:white;

  margin-left: 15vh;
  margin-right: 15vh;
}

.bottomPadding {
  padding-bottom: 40vh;
}

.about_bg {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(0, 228, 160, 0.171)
  ),
  url('../img/poster_wall_portrait_resized.jpg');
  background-size: 140% 100%;
  object-position: -40% 0;
  background-repeat: no-repeat;
}