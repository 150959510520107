html {
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.headerTab {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px;
  float: right;
  padding-top: 2vh;
  order: none;
  align-self: flex-end;
}

.headerTab:hover {
  transform: scale(1.1);
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  height: 800px;
  align-self: center;
}

.title-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1280px;
  height: 300px;
  align-self: center;
  align-items: center;
  padding: 0vh;
}


.subtitle {
  text-align: center;
  padding-top: 5vh;
  font-size: 25px;
  color:white;
}
/*
.langTab {
  color: whitesmoke;
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  float: left;
  padding-top: 1vh;
}

.langTab:hover {
  transform: scale(1.1);
}*/

li {
  color: white;
  align-self: flex-start;
  font-style: normal;
  font-size: 4vh;
  filter: none;
}

.sub_elem {
  padding-top: 0vh;
  padding-right: 5vh;
  color: rgb(15, 23, 51);
  font-style: normal;
  font-size: 1vh;
  align-self: flex-start;
}

.bullet {
  padding-left: 0vh;
  color: white;
  font-size: 3vh;
  list-style: circle;
  font-style: normal;
}

.subtitle_heading {
  align-self: flex-start;
  text-align: left;
  justify-content: left;
  justify-items: left;
  justify-self: left;
  padding-left: 14vh;
  padding-top: 2vh;
  font-size: 45px;
  color: white;
}

.backgroundImg {
  background-image: url('./img/balconies2.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


