.EduBG {
  background-image: linear-gradient(
    rgba(223, 126, 190, 0.25),
    rgba(223, 126, 190, 0.25)
  ),
  url('../img/curved_bookshelf_resized.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
}

.EduPage {
  background-color: rgb(34, 34, 34);
}

.text_block_left {
  background-color: rgb(34, 34, 34);
}

.text_block_right {
  background-color: rgb(34, 34, 34);
}

.nchs_background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
  rgba(182, 167, 159, 0.5)),
  url('../img/lockers_resized.jpg');
  background-repeat: no-repeat;
  background-size: 280% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
  filter: grayscale(65%);
}

.uw_background {
  background-image: linear-gradient(
    rgba(0,0,0,0.5),
    rgba(255, 231, 111, 0.5)
  ),
  url('../img/bookshelf_resized.jpg');
  background-repeat: no-repeat;
  background-size: 280% 100%;
  max-height: 100%;
  width: 50vw;
  height: 100vh;
  filter: grayscale(65%);
}

.edu-section-header {
  color: whitesmoke;
  font-size: 200px;
  text-align: center;
  padding-left: 0vh;
  padding-top: 5vh;
  font-style: italic;
}

.institution {
  color: rgb(223, 126, 190);
  padding-left: 10vh;
  font-size: 11vh;
}

.institution-large {
  color: rgb(223, 126, 190);
  padding-left: 10vh;
  font-size: 15vh;
}

.webpage_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(34 , 34, 34);
  padding: 0vh;
}